export const gameRulesEnglish=
 {
      "title": "Games Rules & How to Play",
      "download": "Download Rules",
      "welcomeMessage": "Hello my friend and a warm welcome to <strong>Saudifootballweekly.com</strong>, the only information site on Saudi football you will ever need.",
      "infoMessage1": "We will inform you about everything that goes on in Saudi football, give you news, statistics, insights, outlooks, and evaluations.",
      "infoMessage2": "But it doesn't stop there! We want to turn you into an expert, a true professional when it comes to Saudi football. So we invented a challenge to test your skills: <strong>‘The Prediction Challenge’</strong>.",
      "whatIsChallenge": "What is the Prediction Challenge?",
      "challengeDescription": "It is completely free of charge as it comes as a bonus with your monthly subscription. The challenge is to predict the winner in <strong>7 of the weekly games</strong> of the Saudi Pro League and the exact result in the other <strong>2 games</strong>.",
      "howItWorks": "How Does It Work?",
      "howItWorksList": {
        "point1": "You receive <strong>8 Tokens</strong> every month with your subscription.",
        "point2": "You can use <strong>2 Tokens per week</strong> to participate in the Prediction Challenge.",
        "point3": "All Tokens will be redistributed to participants who predicted the right results through three reward pools."
      },
      "rewardPools": "Reward Pools",
      "tableHeaders": {
        "pool": "Pool",
        "requirement": "Requirement",
        "reward": "Reward"
      },
      "tableContent": {
        "pool1": {
          "name": "Pool 1",
          "requirement": "7 Games Correct",
          "reward": "Tokens equally shared among participants."
        },
        "pool2": {
          "name": "Pool 2",
          "requirement": "7 Games Correct + 1 Exact Score",
          "reward": "Share of Pool 1 + Pool 2."
        },
        "pool3": {
          "name": "Pool 3",
          "requirement": "7 Games Correct + 2 Exact Scores",
          "reward": "Share of Pool 1 + Pool 2 + Pool 3."
        }
      },
      "jackpot": "If no one gets the results right, we create a jackpot for the respective pool that will roll over to the next week. Have fun while you improve your knowledge of Saudi football!"
    }

  
    // export const gameRulesArabic= {
    //       "title": "قواعد اللعبة وكيفية اللعب",
    //       "download": "تحميل القواعد",
    //       "welcomeMessage": "مرحبًا بك صديقي في <strong>سعودي فوتبول ويكلي</strong>، الموقع الوحيد الذي تحتاجه لمعلومات عن كرة القدم السعودية.",
    //       "infoMessage1": "سنوافيك بكل ما يحدث في كرة القدم السعودية، بما في ذلك الأخبار والإحصائيات والتحليلات.",
    //       "infoMessage2": "ولكن الأمر لا يتوقف عند هذا الحد! نريد أن نحولك إلى خبير حقيقي في كرة القدم السعودية. لذلك اخترعنا تحديًا لاختبار مهاراتك: <strong>تحدي التوقعات</strong>.",
    //       "whatIsChallenge": "ما هو تحدي التوقعات؟",
    //       "challengeDescription": "التحدي مجاني تمامًا كجزء من اشتراكك الشهري. التحدي هو التنبؤ بالفائز في <strong>7 مباريات أسبوعية</strong> من الدوري السعودي للمحترفين والنتيجة الدقيقة في <strong>مباراتين إضافيتين</strong>.",
    //       "howItWorks": "كيف يعمل؟",
    //       "howItWorksList": {
    //         "point1": "ستتلقى <strong>8 رموز</strong> كل شهر مع اشتراكك.",
    //         "point2": "يمكنك استخدام <strong>رمزين أسبوعيًا</strong> للمشاركة في تحدي التوقعات.",
    //         "point3": "ستتم إعادة توزيع جميع الرموز على المشاركين الذين توقعوا النتائج الصحيحة من خلال ثلاث مجموعات مكافآت."
    //       },
    //       "rewardPools": "مجموعات المكافآت",
    //       "tableHeaders": {
    //         "pool": "المجموعة",
    //         "requirement": "المتطلب",
    //         "reward": "المكافأة"
    //       },
    //       "tableContent": {
    //         "pool1": {
    //           "name": "المجموعة 1",
    //           "requirement": "توقع صحيح لـ 7 مباريات",
    //           "reward": "تقاسم الرموز بالتساوي بين المشاركين."
    //         },
    //         "pool2": {
    //           "name": "المجموعة 2",
    //           "requirement": "توقع صحيح لـ 7 مباريات + نتيجة دقيقة واحدة",
    //           "reward": "حصة من المجموعة 1 + المجموعة 2."
    //         },
    //         "pool3": {
    //           "name": "المجموعة 3",
    //           "requirement": "توقع صحيح لـ 7 مباريات + نتيجتين دقيقتين",
    //           "reward": "حصة من المجموعة 1 + المجموعة 2 + المجموعة 3."
    //         }
    //       },
    //       "jackpot": "إذا لم يحصل أحد على النتائج الصحيحة، سننشئ جائزة كبرى للمجموعة المعنية التي ستتدحرج إلى الأسبوع التالي. استمتع بينما تحسن معرفتك بكرة القدم السعودية!"
    //     }
    export const gameRulesArabic = {
      "title": "قواعد اللعبة وكيفية اللعب",
      "download": "تحميل القواعد",
      "welcomeMessage": "مرحباً صديقي، ونرحب بك بحرارة في موقع <strong>Saudifootballweekly.com</strong>، الموقع المعلوماتي الوحيد عن كرة القدم السعودية الذي ستحتاجه على الإطلاق.",
      "infoMessage1": "سنطلعك على كل ما يحدث في كرة القدم السعودية، ونقدم لك الأخبار والإحصاءات والتحليلات والتوقعات والتقييمات.",
      "infoMessage2": "ولكن الأمر لا يتوقف عند هذا الحد! نحن نريد أن نحولك إلى خبير حقيقي، محترف عندما يتعلق الأمر بكرة القدم السعودية. لذلك ابتكرنا تحدياً لاختبار مهاراتك، نطلق عليه اسم <strong>\"تحدي التوقعات\"</strong>.",
      "whatIsChallenge": "ما هو تحدي التوقعات؟",
      "challengeDescription": "هذا التحدي مجاني تماماً، حيث يأتي كميزة إضافية مع اشتراكك الشهري. يقوم التحدي على توقع الفائز في <strong>7 مباريات</strong> من الدوري السعودي للمحترفين في الأسبوع، بالإضافة إلى التنبؤ بالنتيجة الدقيقة في <strong>مباراتين أخريين</strong>.",
      "howItWorks": "كيف يعمل؟",
      "howItWorksList": {
        "point1": "ستحصل على <strong>8 \"توكنز\"</strong> (Tokens) كل شهر مع اشتراكك.",
        "point2": "يمكنك استخدام <strong>2 توكن</strong> كل أسبوع للمشاركة في \"تحدي التوقعات\".",
        "point3": "سيتم إعادة توزيع جميع التوكنز المجمّعة على المشاركين الذين نجحوا في توقع النتائج الصحيحة."
      },
      "rewardPools": "صناديق المكافآت",
      "tableHeaders": {
        "pool": "الصندوق",
        "requirement": "المتطلب",
        "reward": "المكافأة"
      },
      "tableContent": {
        "pool1": {
          "name": "المجموعة 1",
          "requirement": "7 مباريات صحيحة",
          "reward": "سيتم تقاسم جميع التوكنز بالتساوي بين المشاركين الذين توقعوا النتائج بشكل صحيح."
        },
        "pool2": {
          "name": "المجموعة 2",
          "requirement": "7 مباريات صحيحة + نتيجة دقيقة واحدة",
          "reward": "تتضمن نصيبك من المجموعة 1 أيضاً."
        },
        "pool3": {
          "name": "المجموعة 3",
          "requirement": "7 مباريات صحيحة + نتيجتان دقيقتان",
          "reward": "تتضمن نصيبك من المجموعة 2 والمجموعة 1."
        }
      },
      "jackpot": "سيتم تقاسم جميع التوكنز في كل مجموعة بالتساوي بين جميع المشاركين الذين توقعوا النتائج بشكل صحيح. على سبيل المثال، إذا كانت هناك 1000 توكن في المجموعة 1، ونجح 20 مشاركاً في التوقع الصحيح، فسيحصل كل منهم على (1000 ÷ 20 = 50) توكن تُضاف إلى محفظته. ويمكن استخدام التوكنز التي تربحها في الاسترداد أو للمشاركة في تحدي التوقعات الأسبوعي القادم. إذا توقعت النتائج الصحيحة التي تؤهلك للفوز في المجموعة 2، فستحصل تلقائياً على نصيبك من المجموعة 1 أيضاً. وبالنسبة للفائزين في المجموعة 3، فسيحصلون كذلك على نصيب من المجموعة 2 والمجموعة 1. </br> في حال لم ينجح أي شخص في توقع النتائج الصحيحة، سننشئ <strong>\"جائزة كبرى\" (Jackpot)</strong> للمجموعة المعنية، تنتقل قيمتها للأسبوع التالي. </br> استمتع بينما تطوّر معرفتك بكرة القدم السعودية!",
      "enjoy": "استمتع بينما تطوّر معرفتك بكرة القدم السعودية!"
    }
    
      
   