import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { startOfWeek, endOfWeek, format } from "date-fns";
import PredictionPopup from "../predictionPopup";
import SuccessModal from "../success-failure-models/SuccessModal";
import FailureModal from "../success-failure-models/FailureModal";
import { getMyPredictions } from "../../API/ServerApi";

const MyPredictionsMain = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showFailureModal, setShowFailureModal] = useState<boolean>(false);
    const [failureMessage, setFailureMessage] = useState<string>("");
    const [myPredictions, setMyPredictions] = useState<any[]>([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    interface TeamNameProps {
        name: string;
    }
    const TeamName: React.FC<TeamNameProps> = ({ name }) => {
        if (name?.length > 15) {
            const [firstPart, secondPart, ...restParts] = name.split(" ");

            if (restParts?.length > 0) {
                return (
                    <>
                        {firstPart} {secondPart} <br /> {restParts.join(" ")}
                    </>
                );
            } else {
                return (
                    <>
                        {firstPart} <br /> {secondPart || ""}
                    </>
                );
            }
        }
        return <>{name}</>;
    };

    // const Game = ({ game }: any) => {
    //     return (
    //         <div className="grid grid-cols-[5fr,3fr] bg-blue">
    //             <div className="flex flex-row justify-between">
    //                 <img
    //                     src={game.home_team?.logo}
    //                     alt={`${game.home_team?.name} logo`}
    //                     className="w-7 h-7"
    //                 />
    //                 <span className="text-sm">
    //                     <TeamName name={game.home_team?.name} />
    //                 </span>
    //                 <span>-</span>
    //                 <span className="text-sm">
    //                     <TeamName name={game.away_team?.name} />
    //                 </span>
    //                 <img
    //                     src={game.away_team?.logo}
    //                     alt={`${game.away_team?.name} logo`}
    //                     className="w-7 h-7"
    //                 />
    //             </div>

    //             <div className="flex justify-start pl-6 items-center">
    //                 {/* <p
    //                     className={`text-center text-xs font-bold rounded items-center w-[30px] h-[30px] ${game.goals?.home > game.goals?.away
    //                             ? "bg-orange-500 text-white"
    //                             : game.goals?.home < game.goals?.away
    //                                 ? "bg-white"
    //                                 : "bg-gray-200"
    //                         }`}
    //                 >
    //                     {game.goals?.home !== null && game.goals?.away !== null
    //                         ? game.goals?.home > game.goals?.away
    //                             ? "1"
    //                             : game.goals?.home < game.goals?.away
    //                                 ? "2"
    //                                 : "X"
    //                         : ""}
    //                 </p> */}

    //                 <span className={`w-[20px] text-lg rounded
    //                 ${game.goals?.home > game.goals?.away
    //                             ? "bg-gray-200"
    //                             : game.goals?.home < game.goals?.away
    //                                 ? "bg-gray-200"
    //                                 : "bg-gray-200"
    //                         }`} 

    //                         >
    //                                                 {game.goals?.home !== null && game.goals?.away !== null
    //                         ? game.goals?.home > game.goals?.away
    //                             ? "1"
    //                             : game.goals?.home < game.goals?.away
    //                                 ? "2"
    //                                 : "X"
    //                         : ""}
    //                                                 </span>


    //             </div>

    //             <div>
    //                 <span className="text-xs text-gray-600">
    //                     {game.goals?.home != null && game.goals?.away != null
    //                         ? `${game.goals.home}-${game.goals.away}`
    //                         : t("NotPlayed")}
    //                 </span>
    //             </div>
    //         </div>
    //     );
    // };

    // const MyPredictionColumn= ({game}:any)=>{
    //     return (
    //         <div className="grid grid-cols-[5fr,3fr] justify-start items-start">
    //         <div className="flex justify-start items-start gap-4 pl-11 pv-22 bg-red-500">
    //             <span className={`w-[20px] text-lg rounded ${game.choice === "1"
    //                         ? "bg-orange-700 text-white"
    //                         : game.choice === "x"
    //                             ? "bg-white"
    //                             : game.choice === "2"
    //                                 ? "bg-red-200"
    //                                 : "bg-gray-200"
    //                     }`}  >
    //             {game.choice
    //                     ? t(game.choice.toUpperCase())
    //                     : t("No Choice")}
    //             </span>



    //             {/* Success Tick */}
    //             {((game.choice === "1" && game.goals.home > game.goals.away) ||
    //                 (game.choice === "2" && game.goals.home < game.goals.away) ||
    //                 (game.choice === "x" && game.goals.home === game.goals.away)) && (
    //                     <span className="text-white bg-green-700 w-[20px] text-lg rounded">✓</span>
    //                 )}
    //             {((game.choice === "1" && game.goals.home <= game.goals.away) ||
    //                 (game.choice === "2" && game.goals.home >= game.goals.away) ||
    //                 (game.choice === "x" && game.goals.home !== game.goals.away)) && (
    //                     <span className="text-white bg-red-700 w-[20px] text-lg rounded">✗</span>)}
    //         </div>
    //             </div>
    //     )
    // }

    const Game = ({ game }: any) => {
        return (
            <div className="grid grid-cols-[1fr,1fr] items-center gap-4">
                <div className="flex items-center justify-between">
                    <img
                        src={game.home_team?.logo}
                        alt={`${game.home_team?.name} logo`}
                        className="w-7 h-7"
                    />
                    <span className="text-sm">
                        <TeamName name={game.home_team?.name} />
                    </span>
                    <span>-</span>
                    <span className="text-sm">
                        <TeamName name={game.away_team?.name} />
                    </span>
                    <img
                        src={game.away_team?.logo}
                        alt={`${game.away_team?.name} logo`}
                        className="w-7 h-7"
                    />
                    
                </div>

                <div className="flex items-center justify-start">
                <span className="text-lg text-gray-700 font-semibold mr-10 bg-green-200 p-1">
                        {game.goals?.home != null && game.goals?.away != null
                            ? `${game.goals.home}-${game.goals.away}`
                            : t("NotPlayed")}
                    </span>
                    <span
                        className={`w-8 h-8 text-lg flex items-center justify-center rounded-full border font-bold ml-5 ${game.goals?.home > game.goals?.away
                                ? "bg-orange-500 text-white"
                                : game.goals?.home < game.goals?.away
                                    ? "bg-red-500 text-white"
                                    : "bg-gray-300 text-black"
                            }`}
                    >
                        {game.goals?.home !== null && game.goals?.away !== null
                            ? game.goals?.home > game.goals?.away
                                ? "1"
                                : game.goals?.home < game.goals?.away
                                    ? "2"
                                    : "X"
                            : ""}
                    </span>

                </div>
            </div>
        );
    };

    const MyPredictionColumn = ({ game }: any) => {
        return (
            <div className="flex items-center justify-between gap-4">
                {game.choice ? (
                    <>
                        <span
                            className={`w-8 h-8 text-lg flex items-center justify-center rounded-full border font-bold ${game.choice === "1"
                                    ? "bg-orange-700 text-white"
                                    : game.choice === "x"
                                        ? "bg-white text-black border-gray-300"
                                        : game.choice === "2"
                                            ? "bg-red-200 text-black"
                                            : "bg-gray-200 text-black"
                                }`}
                        >
                            {t(game.choice.toUpperCase())}
                        </span>

                        {/* Success or Failure Tick */}
                        {((game.choice === "1" && game.goals.home > game.goals.away) ||
                            (game.choice === "2" && game.goals.home < game.goals.away) ||
                            (game.choice === "x" && game.goals.home === game.goals.away)) && (
                                <span className="w-8 h-8 text-lg flex items-center justify-center rounded-full bg-green-700 text-white">
                                    ✓
                                </span>
                            )}
                        {((game.choice === "1" && game.goals.home <= game.goals.away) ||
                            (game.choice === "2" && game.goals.home >= game.goals.away) ||
                            (game.choice === "x" && game.goals.home !== game.goals.away)) && (
                                <span className="w-8 h-8 text-lg flex items-center justify-center rounded-full bg-red-700 text-white">
                                    ✗
                                </span>
                            )}
                    </>
                ) : game.predicted_score ? (
                    <div className="flex items-center justify-between w-full gap-2">
                        <span
                            className={`w-9 h-9 text-center text-base flex items-center justify-center rounded-full border font-bold bg-blue-200 text-black`}
                        >
                            {game.predicted_score.home}-{game.predicted_score.away}
                        </span>

                        <span
                            className={`w-8 h-8 text-lg flex items-center justify-center rounded-full border font-bold ${(game.predicted_score.home === game.goals?.home &&
                                    game.predicted_score.away === game.goals?.away)
                                    ? "bg-green-700 text-white"
                                    : "bg-red-700 text-white"
                                }`}
                        >
                            {game.predicted_score.home === game.goals?.home &&
                                game.predicted_score.away === game.goals?.away
                                ? "✓"
                                : "✗"}
                        </span>
                    </div>
                ) : (
                    <span className="text-sm text-gray-500">{t("No Prediction")}</span>
                )}
            </div>
        );
    };

    const getMyPreds = async () => {
        setIsLoading(true);
        try {
            const response = await getMyPredictions();
            console.log(response);
            if (!response || !response.success) {
                setIsError(true);
                return;
            }
            setMyPredictions(response.predictions);
        } catch (error) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };
    // const groupGamesByWeek = () => {
    //     const grouped: { [key: string]: { [key: string]: any[] } } = {}; // Group by week -> bet ID -> games

    //     myPredictions.forEach((pred) => {
    //         pred.matches.forEach((game: any) => {
    //             const gameDate = new Date(game.date);
    //             const weekStart = startOfWeek(gameDate, { weekStartsOn: 1 }); // Week starts on Monday
    //             const weekEnd = endOfWeek(gameDate, { weekStartsOn: 1 });
    //             const weekKey = `${format(weekStart, "MMMM dd")} - ${format(
    //                 weekEnd,
    //                 "MMMM dd"
    //             )}`;

    //             if (!grouped[weekKey]) {
    //                 grouped[weekKey] = {};
    //             }
    //             if (!grouped[weekKey][pred.createdAt]) {
    //                 grouped[weekKey][pred.createdAt] = [];
    //             }
    //             grouped[weekKey][pred.createdAt].push(game);
    //         });
    //     });

    //     return grouped;
    // };


    // const groupGamesByWeek = () => {
    //     // const grouped: { [key: string]: { [key: string]: any }[] } = {}; // Group by week -> bet ID -> predictions with matches
    //     const grouped: { [key: string]: { [key: string]: any[] } } = {}; 
    //     myPredictions.forEach((pred) => {
    //         pred.matches.forEach((game: any) => {
    //             const gameDate = new Date(game.date);
    //             const weekStart = startOfWeek(gameDate, { weekStartsOn: 1 }); // Week starts on Monday
    //             const weekEnd = endOfWeek(gameDate, { weekStartsOn: 1 });
    //             const weekKey = `${format(weekStart, "MMMM dd")} - ${format(
    //                 weekEnd,
    //                 "MMMM dd"
    //             )}`;

    //             if (!grouped[weekKey]) {
    //                 grouped[weekKey] = {};
    //             }

    //             if (!grouped[weekKey][pred.createdAt]) {
    //                 grouped[weekKey][pred.createdAt] = {
    //                     predInfo: { ...pred, matches: undefined }, // Preserve pred info except matches
    //                     matches: []
    //                 };
    //             }

    //             grouped[weekKey][pred.createdAt].matches.push(game);
    //         });
    //     });

    //     return grouped;
    // };

    const groupGamesByWeek = () => {
        interface Game {
            date: string;
            [key: string]: any;
        }

        interface Prediction {
            createdAt: string;
            round: string;
            matches: Game[];
            [key: string]: any;
        }

        interface GroupedMatches {
            predInfo: Omit<Prediction, "matches">;
            matches: Game[];
        }

        const grouped: {
            [weekRange: string]: {
                round: string;
                bets: { [createdAt: string]: GroupedMatches };
            };
        } = {};

        myPredictions.forEach((pred: Prediction) => {
            pred.matches.forEach((game: Game) => {
                const gameDate = new Date(game.date);
                const weekStart = startOfWeek(gameDate, { weekStartsOn: 1 }); // Week starts on Monday
                const weekEnd = endOfWeek(gameDate, { weekStartsOn: 1 });
                const weekKey = `${format(weekStart, "MMMM dd")} - ${format(
                    weekEnd,
                    "MMMM dd"
                )}`;

                if (!grouped[weekKey]) {
                    grouped[weekKey] = { round: pred.round, bets: {} };
                }

                if (!grouped[weekKey].bets[pred.createdAt]) {
                    grouped[weekKey].bets[pred.createdAt] = {
                        predInfo: { ...pred, matches: undefined }, // Preserve pred info except matches
                        matches: []
                    };
                }

                grouped[weekKey].bets[pred.createdAt].matches.push(game);
            });
        });

        return grouped;
    };


    useEffect(() => {
        getMyPreds();
    }, []);

    useEffect(() => {
        setProgress(
            myPredictions?.length > 0
                ? Math.round(
                    (myPredictions.reduce(
                        (acc, pred) =>
                            acc +
                            pred.matches.filter(
                                (game: any) =>
                                    game.status?.short === "FT" ||
                                    game.status?.short === "Completed"
                            ).length,
                        0
                    ) /
                        myPredictions.reduce(
                            (acc, pred) => acc + pred.matches.length,
                            0
                        )) *
                    100
                )
                : 0
        );
    }, [myPredictions]);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-gray-500"></div>
            </div>
        );
    }

    if (isError) {
        return (
            <div className="flex justify-center items-center h-screen">
                <span>{t("somethingWrong")}</span>
            </div>
        );
    }

    if (!myPredictions?.length) {
        return (
            <div className="flex flex-col justify-center items-center h-screen space-y-4">
                <span>{t("NoPredictionsYet")}</span>
                <button
                    className="p-3 rounded-lg text-xl bg-[#056A4C] text-white mt-4"
                    onClick={() => setIsPopupOpen(true)}
                >
                    {t("predictionPage")}
                </button>
                {isPopupOpen && (
                    <PredictionPopup handleClosePopup={() => setIsPopupOpen(false)} />
                )}
            </div>
        );
    }

    const groupedGames = groupGamesByWeek();
    console.log("grp games by week", groupedGames);
    return (
        <div className="flex flex-col items-center w-full py-6 bg-gray-100 min-h-screen">

            {/* Header Section */}
            <div className="w-full flex flex-col items-center">
                {Object.entries(groupedGames).map(([weekRange, { round, bets }]) => (
                    <div key={weekRange} className="mb-10 px-4 lg:px-8">

                        {/* Section Title */}
                        <h2 className="text-2xl font-bold mb-6 text-gray-700 text-center">
                            {`(Round ${round}) ${weekRange}`}
                        </h2>

                        {/* Table Header */}
                        <div className="grid grid-cols-5 gap-4 text-center border-b pb-4 mb-6 font-semibold text-gray-600">
                            <div className="col-start-1 col-end-3">{t("MatchHistory")}</div>
                            <div>{t("MyPrediction")}</div>
                            <div>{t("MyResults")}</div>
                            <div className="text-left pl-[10px]">{t("Pools")}</div>
                        </div>

                        {Object.entries(bets).map(([betId, { predInfo, matches }]) => (
                            <div
                                key={predInfo?.bet_id}
                                className="grid grid-cols-1 lg:grid-cols-[3fr,1fr] gap-6 mb-8 bg-white rounded-lg shadow p-6 overflow-hidden"
                            >
                                {/* Matches Section */}
                                <div className="overflow-x-auto">
                                    <h3 className="text-lg font-semibold mb-4 text-gray-800">
                                        {`Created at: ${new Date(betId).toLocaleString('en-SA', { timeZone: 'Asia/Riyadh' })}`}
                                    </h3>
                                    {matches.map((game, index) => (
                                        <div
                                            key={index}
                                            className="grid grid-cols-[2fr,1fr] gap-4 items-center text-center border-b py-4 last:border-b-0"
                                        >
                                            <Game game={game} />
                                            <MyPredictionColumn game={game} />
                                        </div>
                                    ))}
                                </div>

                                {/* Results Section */}
                                <div className="flex justify-center items-center">
                                    <div className="grid gap-4">
                                        {['Pool1', 'Pool2', 'Pool3'].map((pool, idx) => (
                                            <div
                                                key={idx}
                                                className="bg-gray-50 border border-gray-300 rounded-lg shadow p-4 text-center"
                                            >
                                                <p className="font-bold text-lg text-gray-700">{pool}</p>
                                                <p className="text-sm text-gray-500">0 tokens</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            {/* Modals */}
            <SuccessModal
                isOpen={showSuccessModal}
                onClose={() => setShowSuccessModal(false)}
            />
            <FailureModal
                isOpen={showFailureModal}
                onClose={() => setShowFailureModal(false)}
                failureMessage={failureMessage}
            />
            {isPopupOpen && (
                <PredictionPopup handleClosePopup={() => setIsPopupOpen(false)} />
            )}
        </div>
    );



};

export default MyPredictionsMain;
